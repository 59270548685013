
import Intro from "./components/Intro.vue";
import Tabs from "./components/Tabs.vue";
import NoFremiumAccess from "~/components/NoFremiumAccess.vue";

export default {
  components: { Intro, Tabs, NoFremiumAccess },

  inheritAttrs: false,
};
