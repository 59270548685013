
export default {
  inheritAttrs: false,

  data() {
    return {
      tabs: [
        { title: this.$t("PERSON_PAGE_OVERVIEW"), to: "overview" },
        { title: this.$t("PERSON_PAGE_OWNERSHIP"), to: "ownership" },
        { title: this.$t("PERSON_PAGE_TRANSACTIONS"), to: "transactions" },
        { title: this.$t("PERSON_PAGE_RELATIONS"), to: "relations" },
      ],
    };
  },

  computed: {
    activeTab: function () {
      return this.$route.name;
    },
  },

  methods: {
    navigateTo(route) {
      if (this.activeTab.includes(route)) {
        return;
      }

      this.logAmplitudeEvent(route);

      this.$router.replace(this.getRoute(route));
    },

    getTabColor(route) {
      if (this.activeTab.includes(route)) {
        return "--color-primary";
      }

      return "--color-white";
    },

    getActiveState(route) {
      return this.activeTab.includes(route);
    },

    logAmplitudeEvent(route) {
      this.$amplitude.track({
        event_type: `Navigate to person-${route}`,
        event_properties: {
          bfe: this.$route.params.id,
        },
      });
    },

    getRoute(path) {
      return {
        name: `explore-person-id-${path}`,
        params: { id: this.$route.params.id },
      };
    },
  },
};
