
import PersonCard from "~/components/EntityCard/PersonCard.vue";
import Person from "~/graphql/Person/Person.gql";
import { calculatePersonAge } from "~/helpers/person-helpers.js";
import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";

export default {
  components: { PersonCard },

  inheritAttrs: false,

  apollo: {
    person: {
      query: Person,

      update(data) {
        return data.person;
      },

      error(error) {
        console.error("Person failed:", error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.person.loading;
    },

    header() {
      return this.person.name;
    },

    byline() {
      let gender;
      if (this.person.gender === "mand") {
        gender = this.$t("PERSON_PAGE_GENDER_MALE");
      } else if (this.person.gender === "kvinde") {
        gender = this.$t("PERSON_PAGE_GENDER_FEMALE");
      } else {
        gender = this.$t("VALUE_NOT_REGISTERED");
      }

      let age;
      if (this.person.birthday) {
        age = `${calculatePersonAge(this.person.birthday)} ${this.$t("PERSON_PAGE_YEAR_OLD")}`;
      } else {
        age = this.$t("VALUE_NOT_REGISTERED");
      }

      return `
        ${gender},
        ${age}
      `;
    },

    birthday() {
      return this.person.birthday;
    },

    imigrationStatus() {
      switch (this.person.status) {
        case "bopael_i_groenland_hoej_vejkode":
          return this.$t("PERSON_PAGE_BOPAEL_I_GROENLAND_HOEJ_VEJKODE");

        case "bopael_i_groenland":
          return this.$t("PERSON_PAGE_BOPAEL_I_GROENLAND");

        case "bopael_i_danmark":
          return this.$t("PERSON_PAGE_BOPAEL_I_DANMARK");

        case "forsvundet":
          return this.$t("PERSON_PAGE_FORSVUNDET");

        case "ej_bopael":
          return this.$t("PERSON_PAGE_EJ_BOPAEL");

        case "doed":
          return this.$t("PERSON_PAGE_DOED");

        case "udrejst":
          return this.$t("PERSON_PAGE_UDREJST");

        case "bopael_i_danmark_hoej_vejkode":
          return this.$t("PERSON_PAGE_BOPAEL_I_DANMARK_HOEJ_VEJKODE");

        default:
          return this.$t("VALUE_NOT_REGISTERED");
      }
    },

    creditWarning() {
      return this.person.creditWarning ? this.$t("PERSON_PAGE_FLAG_YES") : this.$t("PERSON_PAGE_FLAG_NO");
    },

    creditWarningTooltip() {
      return "tooltip...";
    },

    localAddressProtection() {
      return this.person.localAddressProtection ? this.$t("PERSON_PAGE_FLAG_YES") : this.$t("PERSON_PAGE_FLAG_NO");
    },

    localAddressProtectionTooltip() {
      return "tooltip...";
    },

    addressProtection() {
      return this.person.addressProtection ? this.$t("PERSON_PAGE_FLAG_YES") : this.$t("PERSON_PAGE_FLAG_NO");
    },

    addressProtectionTooltip() {
      return "tooltip...";
    },

    address() {
      const a = `
        ${this.person.streetName || ""}
        ${this.person.streetNumber || ""}
        ${this.person.floorNumber || ""}
        ${this.person.doorNumber || ""}
      `.trim();

      const b = `
        ${this.person.postalCode || ""}
        ${this.person.city || ""}
      `.trim();

      let r = "";

      if (a.length > 0) {
        r += a;
      }

      if (b.length > 0) {
        r += `, ${b}`;
      }

      if (r.length === 0) {
        return null;
      }

      return r;
    },

    foreignAddress() {
      const a = `
        ${this.person.foreignAddressLine1 || ""}
        ${this.person.foreignAddressLine2 || ""}
        ${this.person.foreignAddressLine3 || ""}
        ${this.person.foreignAddressLine4 || ""}
        ${this.person.foreignAddressLine5 || ""}
      `.trim();

      const b = `
        ${this.person.foreignAddressCountry || ""}
      `.trim();

      let r = "";

      if (a.length > 0) {
        r += a;
      }

      if (b.length > 0) {
        r += `, ${b}`;
      }

      if (r.length === 0) {
        return null;
      }

      return r;
    },
  },
};
